import moment from "moment";
import variables from '@/styles/element-variables.scss';
import defaultSettings from '@/settings';
var tagsView = defaultSettings.tagsView,
  fixedHeader = defaultSettings.fixedHeader,
  sidebarLogo = defaultSettings.sidebarLogo,
  showRefresh = defaultSettings.showRefresh,
  refreshDefault = defaultSettings.refreshDefault,
  showCouponErr = defaultSettings.showCouponErr,
  couponErr = defaultSettings.couponErr;
var state = {
  theme: variables.theme,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  showRefresh: showRefresh,
  refreshDefault: refreshDefault,
  refreshAction: false,
  refreshDate: moment().format("YYYY-MM-DD HH:mm:ss"),
  showCouponErr: showCouponErr,
  // 是否展示优惠券驳回原因
  couponErr: '' // 优惠券驳回原因
};

var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
      value = _ref.value;
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};