import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/* Router Modules */
import { medicalServiceRouter, tradeRouter, personRouter, contentRouter, dataRouter, marketingRouter, merchantRouter, merchantStore, settingRouter } from "./modules/index";
/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [{
  path: '/redirect',
  component: Layout,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return import('@/views/redirect/index');
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return import('@/views/login/index');
  },
  hidden: true
}, {
  path: '/forget-password',
  component: function component() {
    return import('@/views/login/forgetPassword/index');
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return import('@/views/login/auth-redirect');
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return import('@/views/error-page/404');
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return import('@/views/error-page/401');
  },
  hidden: true
}, {
  path: '/',
  component: Layout,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return import('@/views/dashboard/index');
    },
    name: 'dashboard',
    meta: {
      title: '工作台',
      icon: 'dashboard',
      affix: true
    }
  }]
}, {
  path: '/excelClean',
  component: function component() {
    return import('@/views/clean/index');
  },
  hidden: true
}];
// 4.4.9 工作台页面也可控制权限，但页面保留，仅仅控制页面内容是否显示

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export var asyncRoutes = [medicalServiceRouter, tradeRouter, personRouter, contentRouter, dataRouter, marketingRouter, merchantRouter, merchantStore, settingRouter,
// constantRoutes[constantRoutes.length - 1].children[0],
/** when your routing map is too long, you can split it into small modules **/
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;