import axios from 'axios';
import { MessageBox, Message, Notification } from 'element-ui';
import store from '@/store';
import { getToken, getUserID } from '@/utils/auth';
// import { MessageBox } from 'element-ui'

// create an axios instance
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_TARGET + process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout
});

// request interceptor
service.interceptors.request.use(function (config) {
  // do something before request is sent

  if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['Authorization'] = getToken();
    config.headers['userid'] = getUserID();
  }
  if (!!config.version) {
    config.baseURL = config.baseURL + config.version;
  } else {
    config.baseURL = config.baseURL + 'v1';
  }
  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  if (res.message) {
    // if the custom code is not 20000, it is judged as an error.
    if (res.code === 1001 || res.code == 1002 || res.code === 1003 || res.code === 1004 || res.message.status_code === 1001 || res.message.status_code === 1002 || res.message.status_code === 1003) {
      MessageBox.confirm('验证过期，请重新登录', '重新登录', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        store.dispatch('user/resetToken').then(function () {
          location.reload();
        });
      }).catch(function () {});
      return Promise.reject(res);
    } else if (res.success == false) {
      Notification({
        title: '服务器反馈',
        message: res.message,
        type: 'error',
        duration: 5 * 1000
      });
      res.message = undefined;
      return Promise.reject(res);
    }
    return response;
  } else {
    return response;
  }
}, function (error) {
  console.log('err' + error); // for debug
  Notification({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
export default service;