import { getComplexDrug, setComplexDrug as _setComplexDrug, getPopSciArticleList, setPopSciArticleList as _setPopSciArticleList, getSingleDrug, setSingleDrug as _setSingleDrug, getStoreSingleDrugList, setStoreSingleDrugList, getStoreComplexDrugList, setStoreComplexDrugList } from '@/utils/draft';
var state = {
  complexDrugList: getComplexDrug(),
  // 组合药品草稿列表
  popSciArticleList: getPopSciArticleList(),
  // 文章草稿列表
  singleDrugList: getSingleDrug(),
  // 单个药品草稿列表
  storeSingleDrugList: getStoreSingleDrugList(),
  // 店铺单个药品草稿列表
  storeComplexDrugList: getStoreComplexDrugList() // 店铺组合药品草稿列表
};

var mutations = {
  SET_COMPLEX_DRUG: function SET_COMPLEX_DRUG(state, data) {
    state.complexDrugList = data;
  },
  SET_POP_SCI_ARTICLE_LIST: function SET_POP_SCI_ARTICLE_LIST(state, data) {
    state.popSciArticleList = data;
  },
  SET_SINGLE_DRUG: function SET_SINGLE_DRUG(state, data) {
    state.singleDrugList = data;
  },
  SET_STORE_SINGLE_DRUG: function SET_STORE_SINGLE_DRUG(state, data) {
    state.storeSingleDrugList = data;
  },
  SET_STORE_COMPLEX_DRUG: function SET_STORE_COMPLEX_DRUG(state, data) {
    state.storeComplexDrugList = data;
  }
};
var actions = {
  setComplexDrug: function setComplexDrug(_ref, data) {
    var commit = _ref.commit;
    commit('SET_COMPLEX_DRUG', data);
    _setComplexDrug(data);
  },
  setPopSciArticleList: function setPopSciArticleList(_ref2, data) {
    var commit = _ref2.commit;
    commit('SET_POP_SCI_ARTICLE_LIST', data);
    _setPopSciArticleList(data);
  },
  setSingleDrug: function setSingleDrug(_ref3, data) {
    var commit = _ref3.commit;
    commit('SET_SINGLE_DRUG', data);
    _setSingleDrug(data);
  },
  setStoreSingleDrug: function setStoreSingleDrug(_ref4, data) {
    var commit = _ref4.commit;
    commit('SET_STORE_SINGLE_DRUG', data);
    setStoreSingleDrugList(data);
  },
  setStoreComplexDrug: function setStoreComplexDrug(_ref5, data) {
    var commit = _ref5.commit;
    commit('SET_STORE_COMPLEX_DRUG', data);
    setStoreComplexDrugList(data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};